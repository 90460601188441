import React, { FC, useCallback, useEffect, useState } from 'react';
import {
    Button,
    Checkbox,
    FormControlLabel,
    IconButton,
    LinearProgress,
    Paper,
    styled,
    Typography,
} from '@mui/material';
import { ProjectCard } from './ProjectCard';
import { ProjectForm } from './ProjectForm';
import { Add, AddCircleOutlined, Archive, Restore } from '@mui/icons-material';
import { observer } from 'mobx-react';
import { projectsStore } from '../../stores/projectsStore';
import { NoDataPlaceholder } from '../../components/NoDataPlaceholder';
import { useNavigate } from 'react-router';
import { messageStore } from '../../stores/messageStore';
import { employeesStore } from '../../stores/employeesStore';
import { archiveProjectRequest } from './projectArchiveRequest';
import { restoreProjectRequest } from './projectRestoreRequest';
import { ConfirmationDialog } from '../../components/ConfirmationDialog';
import { useMobileLayout } from '../../hooks';

const Root = styled(Paper)(({ theme }) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    padding: theme.spacing(2),
    boxSizing: 'border-box',
    [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1.5),
    },
}));

const Container = styled('div')(({ theme }) => ({
    width: 'auto',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    overflowY: 'auto',
    height: 'calc(100vh - 180px)',
    [theme.breakpoints.down('sm')]: {
        height: 'calc(100vh - 152px)',
    },
}));

const ControlArea = styled('div')(({ theme }) => ({
    width: 'auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: theme.spacing(1),
}));

export const Projects: FC = observer(() => {
    const [open, setOpen] = useState<boolean>(false);
    const [archiveId, setArchiveId] = useState<string | null>(null);
    const [restoreId, setRestoreId] = useState<string | null>(null);

    useEffect(() => {
        projectsStore.refresh();
    }, []);

    const navigate = useNavigate();

    const isMobile = useMobileLayout();

    const onComplete = useCallback(
        (id?: string) => {
            setOpen(false);
            if (id) navigate(`/projects/${id}`);
        },
        [navigate, setOpen]
    );

    const onArchiveChange = useCallback((value: boolean) => {
        projectsStore.setIncludeArchive(value);
    }, []);

    const onArchiveAnswer = useCallback(
        async (answer: boolean) => {
            if (!archiveId) return;

            setArchiveId(null);

            if (!answer) return;

            archiveProjectRequest
                .request({ id: archiveId })
                .then(() => messageStore.success('Проект помещен в архив'))
                .then(() => projectsStore.refresh());
        },
        [archiveId, setArchiveId]
    );

    const onRestoreAnswer = useCallback(
        async (answer: boolean) => {
            if (!restoreId) return;

            setRestoreId(null);

            if (!answer) return;

            restoreProjectRequest
                .request({ id: restoreId })
                .then(() => messageStore.success('Проект восстановлен'))
                .then(() => projectsStore.refresh());
        },
        [restoreId, setRestoreId]
    );

    const disabled = projectsStore.isLoading || archiveProjectRequest.isLoading || restoreProjectRequest.isLoading;

    return (
        <Root>
            <ControlArea>
                <FormControlLabel
                    control={
                        <Checkbox
                            value={employeesStore.includeArchive}
                            onChange={e => onArchiveChange(e.target.checked)}
                            disabled={disabled}
                        />
                    }
                    label={'Архивные'}
                />
                {isMobile ? (
                    <IconButton
                        onClick={() => {
                            setOpen(() => true);
                        }}
                        disabled={disabled}
                        size="large"
                        color="primary"
                    >
                        <AddCircleOutlined />
                    </IconButton>
                ) : (
                    <Button
                        variant="contained"
                        onClick={() => {
                            setOpen(() => true);
                        }}
                        endIcon={<Add />}
                        disabled={disabled}
                    >
                        Создать
                    </Button>
                )}
            </ControlArea>
            {projectsStore.isLoading && <LinearProgress />}
            {projectsStore.error && <Typography color="error">{projectsStore.error.message}</Typography>}
            {projectsStore.data?.length === 0 && <NoDataPlaceholder />}
            {projectsStore.data && (
                <Container>
                    {projectsStore.data.map(item => (
                        <ProjectCard
                            project={item}
                            key={item.id}
                            onRestore={() => setRestoreId(item.id)}
                            onArchive={() => setArchiveId(item.id)}
                            disabled={disabled}
                            clickable={true}
                        />
                    ))}
                </Container>
            )}
            <ProjectForm open={open} onClose={onComplete} />
            <ConfirmationDialog
                title="Переместить в архив?"
                message="Действительно переместить в архив проект?"
                info="После этого он остается доступным для восстановления"
                open={!!archiveId}
                onClose={onArchiveAnswer}
                icon={<Archive />}
            />
            <ConfirmationDialog
                title="Восстановить?"
                message="Действительно восстановить проект?"
                open={!!restoreId}
                onClose={onRestoreAnswer}
                icon={<Restore />}
            />
        </Root>
    );
});
